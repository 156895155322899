
//**********************************************
//** Display Mobile
//**********************************************
//@media screen and (max-width: 1295px) {
    nav.navbar a.navbar-item img {
        width: 60px;
        max-height: 1.75rem;
    }
//}

//**********************************************
//** Display Desktop
//**********************************************
@media only screen and (min-width: 768px) {
    nav.navbar a.navbar-item img {
        width: 115px;
        max-height: 60px;
    }
  }
